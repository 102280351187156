import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IProductDTO, UserInput, UserInputErrors} from '../types/app-types';
import {
  actualPrice,
  actualSku,
  formatCustomTextFields,
  validateUserInputs,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {getDefaultProduct, getProductBySlug} from './getProduct';
import {AddToCartActionOption, BiButtonActionType, APP_DEFINITION_ID} from '@wix/wixstores-client-core';
import {GetFirstProductLargeQuery, GetProductBySlugLargeQuery} from '../../graphql/queries-schema';
import {ProductOptionsService} from '@wix/wixstores-client-core/dist/es/src/productOptions/ProductOptionsService';
import {
  getProductVariantBySelectionIds,
  IProductSelectionAvailabilityMap,
} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {IProduct} from '@wix/wixstores-graphql-schema';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {BI_APP_NAME} from '../constants';
import {CartActions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/actions/CartActions/CartActions';
import {SPECS} from '../specs';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {IEcomPlatformPublicApi} from '@wix/wixstores-client-storefront-sdk';
import {STORES_APP_DEF_ID} from '@wix/wixstores-client-core/dist/src/constants';

export interface ProductVariantInfo {
  variantSelectionIds: number[];
  selectionsAvailability: IProductSelectionAvailabilityMap;
  mediaItems: IProduct['media'];
  priceInfo: Pick<IProduct, 'formattedPrice' | 'price' | 'comparePrice' | 'formattedComparePrice'>;
}

export interface ProductServiceProps {
  handleSelectionsChange: () => void;
  variantInfo: ProductVariantInfo;
}

export class ProductService {
  public options?: ProductOptionsService;
  private product: IProductDTO;
  private readonly withPriceRange: boolean;
  private readonly siteStore: SiteStore;
  private readonly cartActions: CartActions;
  private readonly wixCodeApi: ControllerParams['flowAPI']['controllerConfig']['wixCodeApi'];

  constructor({
    siteStore,
    withPriceRange,
    origin,
    wixCodeApi,
  }: {
    siteStore: SiteStore;
    withPriceRange: boolean;
    origin: string;
    wixCodeApi: ControllerParams['flowAPI']['controllerConfig']['wixCodeApi'];
  }) {
    this.siteStore = siteStore;
    this.withPriceRange = withPriceRange;
    this.cartActions = new CartActions({siteStore: this.siteStore, origin});
    this.wixCodeApi = wixCodeApi;
  }

  public static mapUserInputsToSelectedOptions(product: IProductDTO, userInputs: UserInput): Record<string, string> {
    return userInputs.selection.reduce((acc, selected) => {
      const optionType = product.options.find((option) => option.selections.find(({id}) => id === selected.id));
      // istanbul ignore else: missing else :(
      if (optionType) {
        acc[optionType.key] = selected.key;
      }
      return acc;
    }, {});
  }

  public static getProductId(product: IProductDTO) {
    return product.id;
  }

  public static getQuantity(userInputs: UserInput): number {
    return userInputs.quantity[0];
  }

  public static mapOptionSelectionIds(userInputs: UserInput): number[] {
    return userInputs.selection.map((selected) => selected.id);
  }

  public static getSubscriptionOptionId(userInputs: UserInput): string | undefined {
    return userInputs.subscriptionPlan[0]?.id;
  }

  public static mapCustomTextFields(
    product: IProductDTO,
    userInputs: UserInput
  ): {title: string; value: string; key?: string; isMandatory?: boolean}[] {
    return formatCustomTextFields(product, userInputs).map((field) => ({
      key: field.customText.key,
      value: field.answer,
      title: field.customText.title,
      isMandatory: field.customText.isMandatory,
    }));
  }

  public static getSelectedVariantId(
    product: IProductDTO,
    userInputs: UserInput,
    // istanbul ignore next: tested
    isRemoveModifiersSelectionIdsFromUserSelections: boolean = false
  ): string | undefined {
    const optionSelectionIds = userInputs.selection.map((selected) => selected.id);
    const selectedVariant = getProductVariantBySelectionIds({
      product,
      variantSelectionIds: optionSelectionIds,
      isRemoveModifiersSelectionIdsFromUserSelections,
    });

    return selectedVariant?.id;
  }

  public updateOptions(product: IProductDTO): void {
    this.product = product;
    this.options = new ProductOptionsService({
      product,
      shouldMutateSelections: false,
      shouldRemoveModifiersFromSelections: this.siteStore.experiments.enabled(
        SPECS.ShouldRemoveModifiersFromSelections
      ),
    });
  }

  public async getProductBySlug(
    slug: string,
    externalId: string,
    withCheckoutCurrencies: boolean
  ): Promise<GetProductBySlugLargeQuery> {
    const {data} = await getProductBySlug(
      this.siteStore,
      slug,
      externalId,
      this.withPriceRange,
      withCheckoutCurrencies
    );
    return data;
  }

  public async getDefaultProduct(
    externalId: string,
    withCheckoutCurrencies: boolean
  ): Promise<GetFirstProductLargeQuery> {
    const {data} = await getDefaultProduct(this.siteStore, externalId, this.withPriceRange, withCheckoutCurrencies);
    return data;
  }

  public validate(userInputs: UserInput, isValidateInventory?: boolean): UserInputErrors {
    return validateUserInputs(this.product, userInputs, isValidateInventory);
  }

  public shouldNavigateToCart(): boolean {
    return this.cartActions.shouldNavigateToCart();
  }

  /* istanbul ignore next: todo: test */
  public async addToCart({
    product,
    userInputs,
    addToCartAction = AddToCartActionOption.MINI_CART,
    onSuccess,
    selectedVariant,
    navigation,
    isPreOrderState,
    usingStoresViewerScript,
  }: {
    product: IProductDTO;
    userInputs: UserInput;
    addToCartAction: AddToCartActionOption;
    onSuccess: () => void;
    selectedVariant: IOptionSelectionVariant;
    navigation: {navigationClick?: string; isNavigateCart: boolean};
    isPreOrderState: boolean;
    usingStoresViewerScript: boolean;
  }): Promise<any> {
    const {isNavigateCart, navigationClick} = navigation;

    const productId = ProductService.getProductId(product);
    const customTextFieldSelections = ProductService.mapCustomTextFields(product, userInputs);
    const optionsSelectionsIds = ProductService.mapOptionSelectionIds(userInputs);
    const quantity = ProductService.getQuantity(userInputs);
    const variantId = ProductService.getSelectedVariantId(
      product,
      userInputs,
      this.siteStore.experiments.enabled(SPECS.REMOVE_MODIFIERS_SELECTION_IDS_FROM_USER_SELECTIONS)
    );
    const optionsSelectionsByNames = ProductService.mapUserInputsToSelectedOptions(product, userInputs);
    const subscriptionOptionId = ProductService.getSubscriptionOptionId(userInputs);
    const trackData = {
      id: product.id,
      sku: actualSku(product, selectedVariant),
      type: product.productType,
      name: product.name,
      price: actualPrice(product, selectedVariant),
      buttonType: isPreOrderState ? BiButtonActionType.PreOrder : BiButtonActionType.AddToCart,
      appName: BI_APP_NAME,
      productType: this.product.productType as any,
      isNavigateCart,
      navigationClick,
    };

    const publicApi: IEcomPlatformPublicApi = await this.wixCodeApi.site.getPublicAPI(
      usingStoresViewerScript ? STORES_APP_DEF_ID : APP_DEFINITION_ID
    );

    await publicApi.cart.addToCart(
      productId,
      quantity,
      {
        customTextFields: customTextFieldSelections,
        choices: optionsSelectionsByNames,
        optionsSelectionsIds,
        addToCartAction,
        subscriptionOptionId,
        variantId,
      },
      isPreOrderState,
      trackData
    );

    onSuccess();
  }
}
